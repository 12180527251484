import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {Helmet} from 'react-helmet';
import PageContext from 'contexts/PageContext';

const BlogPost = ({ pageContext }) => {
  const { title, content, description, image, tags } = pageContext;

  const richTextHTML = documentToHtmlString(JSON.parse(content.content));

  return (
    <PageContext.Provider value={pageContext}>
        <Helmet title={title}>
            <meta name="description" content={description} />
            <meta name="image" content={image.file.url} />
            <meta name="Keywords" content={tags.map(({value}) => value).join(', ')}/>
        </Helmet>

        <section className="card section">
            <h1
                className="title is-1"
                dangerouslySetInnerHTML={{ __html: title }}
            />

            <img className="mb-6 is-hidden-mobile" src={image.file.url} alt={image.title} />

            <section
                className="content is-medium"
                dangerouslySetInnerHTML={{ __html: richTextHTML }}
            />
        </section>
    </PageContext.Provider>
  );
};

export default BlogPost;
